import React, { ReactNode } from 'react';
import { Typography } from 'antd';
import { createStyle } from '../../../utils/tools';
import { normalTextColor } from '../../constants/colors';

const { Title, Text } = Typography;

type SectionViewProps = {
  children: ReactNode;
  title?: string;
  subtitle?: string;
  backgroundColor?: string;
  backgroundImg?: string;
  useCover?: boolean;
  titleColor?: string;
};

const SectionView: React.FC<SectionViewProps> = (props) => {
  const { useCover = false } = props;

  if (props.backgroundImg && useCover) {
    return (
      <section
        style={{
          ...styles.rootContainer,
          ...styles.backgroundImageStyle,
          backgroundColor: props.backgroundColor
            ? props.backgroundColor
            : 'white',
          backgroundImage: props.backgroundImg
            ? `url(${props.backgroundImg})`
            : '',
        }}
      >
        <div style={styles.cover}></div>
        {props.title !== undefined && (
          <Title
            level={2}
            style={{
              textAlign: 'center',
              color: !props.titleColor ? normalTextColor : props.titleColor,
            }}
          >
            {props.title}
          </Title>
        )}
        {props.subtitle !== undefined && (
          <Text
            type="secondary"
            style={{
              textAlign: 'center',
              display: 'flex',
              justifyContent: 'center',
              marginTop: -10,
              fontSize: 18,
            }}
          >
            {props.subtitle}
          </Text>
        )}
        <div className="content" style={styles.content}>
          {props.children}
        </div>
      </section>
    );
  }

  return (
    <section
      style={{
        ...styles.rootContainer,
        ...styles.backgroundImageStyle,
        backgroundColor: props.backgroundColor
          ? props.backgroundColor
          : 'white',
      }}
    >
      {props.title !== undefined && (
        <Title
          level={2}
          style={{
            textAlign: 'center',
            color: !props.titleColor ? normalTextColor : props.titleColor,
          }}
        >
          {props.title}
        </Title>
      )}
      {props.subtitle !== undefined && (
        <Text
          type="secondary"
          style={{
            textAlign: 'center',
            display: 'flex',
            justifyContent: 'center',
            marginTop: -10,
            fontSize: 18,
          }}
        >
          {props.subtitle}
        </Text>
      )}
      <div className="content" style={styles.content}>
        {props.children}
      </div>
    </section>
  );
};

const styles = createStyle({
  rootContainer: {
    padding: 32,
    position: 'relative',
  },
  content: {
    marginTop: 20,
  },
  backgroundImageStyle: {
    backgroundAttachment: 'fixed',
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
  },
  cover: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    width: '100%',
    height: '100%',
    backgroundColor: 'rgba(0,0,0,0.4)',
    backdropFilter: ' blur(6px)',
  },
});

export default SectionView;
