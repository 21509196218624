import React from 'react';
import SectionView from '../common/SectionView';
import { Steps, Grid } from 'antd';
import { useTranslation } from 'react-i18next';

const { Step } = Steps;
const { useBreakpoint } = Grid;

const FooterFeatures = () => {
  const screens = useBreakpoint();
  const { t } = useTranslation();

  return (
    <SectionView>
      <Steps
        direction={screens.md ? 'horizontal' : 'vertical'}
        labelPlacement="vertical"
        style={
          screens.md ? { alignItems: 'flex-start' } : { alignItems: 'center' }
        }
      >
        <Step
          status="finish"
          title={t('footer.features.oneStop.title')}
          description={t('footer.features.oneStop.subtitle')}
        />
        <Step
          status="finish"
          title={t('footer.features.lowPrices.title')}
          description={t('footer.features.lowPrices.subtitle')}
        />
        <Step
          status="finish"
          title={t('footer.features.newCostumer.title')}
          description={t('footer.features.newCostumer.subtitle')}
        />
        <Step
          status="finish"
          title={t('footer.features.contactless.title')}
          description={t('footer.features.contactless.subtitle')}
        />
        <Step
          status="finish"
          title={t('footer.features.customerService.title')}
          description={t('footer.features.customerService.subtitle')}
        />
      </Steps>
    </SectionView>
  );
};

export default FooterFeatures;
