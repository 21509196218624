import React from 'react';
import { createStyle } from '../../../utils/tools';
import { backGroundLight } from '../../constants/colors';

const Divider = () => {
  return <span style={styles.divider}></span>;
};

const styles = createStyle({
  divider: {
    width: '60%',
    height: 1,
    display: 'block',
    backgroundColor: backGroundLight,
    margin: 'auto',
    marginTop: 10,
    marginBottom: 10,
  },
});

export default Divider;
