import React from 'react';
import { Layout } from 'antd';
import { Link } from 'gatsby';
import HeaderNav from './widgets/HeaderNav';
import { screenMaxWidth } from '../constants/colors';
import { createStyle } from '../../utils/tools';

const { Header } = Layout;

const PageHeader = ({ location }) => {
  return (
    <Header className="page-header" style={styles.header}>
      <div style={styles.brandContainer}>
        <Link to={'/' + location.search}>
          <img style={styles.logo} src="/images/logo-green.png" />
        </Link>
      </div>
      <HeaderNav location={location} />
    </Header>
  );
};

const styles = createStyle({
  header: {
    position: 'fixed',
    zIndex: 1,
    width: '100%',
    maxWidth: screenMaxWidth,
    backgroundColor: '#fbfbfb',
    display: 'flex',
    alignItems: 'center',
    padding: 0,
    justifyContent: 'space-between',
  },
  brandContainer: {
    marginLeft: 50,
  },
  logo: {
    height: 32,
  },
});

export default PageHeader;
