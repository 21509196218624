import React, { useState } from 'react';
import { Link } from 'gatsby';
import { Radio, Grid } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars } from '@fortawesome/free-solid-svg-icons';
import { useTranslation } from 'react-i18next';
import { slide as NavMenu } from 'react-burger-menu';
import { tintColorLight } from '../../constants/colors';
import { useUpdateEffect } from 'ahooks';
import { navigationMenus } from '../../constants/config';
import { createStyle } from '../../../utils/tools';

const { useBreakpoint } = Grid;

const HeaderNav = ({ location }) => {
  const { t, i18n } = useTranslation();

  const [lang, setLang] = useState<string>(i18n.language);

  const handleLangChange = (e) => {
    setLang(e.target.value);
  };

  useUpdateEffect(() => {
    i18n.changeLanguage(lang);
  }, [lang]);

  const screens = useBreakpoint();

  const renderNavMenu = navigationMenus.map((item) => {
    return (
      // <Menu.Item className="nav-item" key={item.name}>
      <Link
        to={item.route + location.search}
        key={item.name}
        activeStyle={styles.activeMenu}
        style={screens.md ? styles.menuItemDesktop : {}}
      >
        {t(item.name)}
      </Link>
      // </Menu.Item>
    );
  });

  return (
    <nav style={styles.nav}>
      {!screens.md ? (
        <NavMenu
          styles={navStyles}
          customBurgerIcon={
            <FontAwesomeIcon icon={faBars} color={tintColorLight} />
          }
          right
        >
          {renderNavMenu}
          <div style={styles.languageRadio}>
            <Radio.Group value={lang} onChange={handleLangChange}>
              <Radio.Button value="zh-cn">中文</Radio.Button>
              <Radio.Button value="en">English</Radio.Button>
            </Radio.Group>
          </div>
        </NavMenu>
      ) : (
        <nav style={styles.nav}>
          {renderNavMenu}
          <div style={styles.languageRadioDesktop}>
            <Radio.Group value={lang} onChange={handleLangChange}>
              <Radio.Button value="zh-cn">中文</Radio.Button>
              <Radio.Button value="en">English</Radio.Button>
            </Radio.Group>
          </div>
        </nav>
      )}
    </nav>
  );
};

const styles = createStyle({
  nav: {
    justifyContent: 'flex-end',
    overflow: 'hidden',
    display: 'flex',
  },
  menu: {
    backgroundColor: 'transparent',
    flex: 1,
    justifyContent: 'flex-end',
    overflow: 'hidden',
    display: 'flex',
  },
  languageRadio: {
    display: 'flex',
    alignItems: 'center',
    marginTop: 16,
  },
  languageRadioDesktop: {
    display: 'flex',
    alignItems: 'center',
    marginLeft: 16,
    marginRight: 50,
  },
  menuItemDesktop: {
    paddingLeft: 10,
    paddingRight: 10,
  },
  activeMenu: {
    borderBottom: '2px solid ' + tintColorLight,
  },
});

const navStyles = createStyle({
  bmBurgerButton: {
    position: 'fixed',
    right: 36,
    top: 10,
    width: '36px',
    height: '30px',
  },
  bmBurgerBars: {
    // background: '#373a47',
  },
  bmBurgerBarsHover: {
    // background: '#a90000',
  },
  bmCrossButton: {
    height: 36,
    width: 36,
  },
  bmCross: {
    background: '#bdc3c7',
  },
  bmMenuWrap: {
    position: 'fixed',
    height: '100%',
    top: 0,
    width: 250,
  },
  bmMenu: {
    background: 'white',
    padding: '2.5em 1.5em 0',
    fontSize: '1.15em',
  },
  bmMorphShape: {
    fill: '#373a47',
  },
  bmItemList: {
    color: '#b8b7ad',
    padding: '0.8em',
    display: 'flex',
    flexDirection: 'column',
  },
  bmItem: {
    // display: 'inline-block',
    width: 'fit-content',
    height: 50,
  },
  bmOverlay: {
    background: 'rgba(0, 0, 0, 0.3)',
    top: 0,
    left: 0,
  },
});

export default HeaderNav;
