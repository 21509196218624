/**
 * Function for breaking string value into paragraphs' array
 * @param str long string with \n symbol for breaking into paragraphs
 */
export const stringBreakToParagraphs = (str: string) => {
  return str.split("\n");
};

/**
 *
 * @param styleObject CSS in JS style object
 */
export const createStyle = (styleObject: {
  [key: string]: React.CSSProperties;
}) => styleObject;

/**
 * Generate html format code based on the form values
 * @param values form object from ant design
 */
export const generateEmailFormat = (values: object): string => {
  let output = "<section>";
  for (const [key, value] of Object.entries(values)) {
    if (key === "message") {
      output += `<h4>${key[0].toUpperCase()}${key.slice(1)}</h4><article>${
        value.replace(/\n/g, "<br>")
      }</article>`;
    } else {
      output += `<h4>${key[0].toUpperCase()}${
        key.slice(1)
      }</h4><p>${value}</p>`;
    }
  }
  output += "</section>";
  return output;
};
