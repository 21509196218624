import React from 'react';
import i18n from '../i18n';
import PageHeader from './PageHeader';
import PageFooter from './PageFooter';
import { Layout, BackTop } from 'antd';
import { screenMaxWidth } from '../constants/colors';
import { createStyle } from '../../utils/tools';
import { Helmet } from 'react-helmet';

type PageContainerProps = {
  children: React.ReactNode;
  backgroundColor?: string;
  pageTitle?: string;
  location?: any;
};

const PageContainer: React.FC<PageContainerProps> = ({
  children,
  backgroundColor,
  pageTitle = '让北美生活更美好',
  location,
}) => {
  /** get current language for udesk */
  const udesk_plugin = i18n.language == 'en' ? '137' : '135';

  const script = `
    (function(a,h,c,b,f,g){a["UdeskApiObject"]=f;a[f]=a[f]||function(){(a[f].d=a[f].d||[]).push(arguments)};g=h.createElement(c);g.async=1;g.charset="utf-8";g.src=b;c=h.getElementsByTagName(c)[0];c.parentNode.insertBefore(g,c)})(window,document,"script","https://assets-cli.s5.udesk.cn/im_client/js/udeskApi.js","ud");
    ud({
        "code": "130khf",
        "link": "https://luniumall.s5.udesk.cn/im_client/?web_plugin_id=${udesk_plugin}",
    });
  `;

  return (
    <Layout
      style={{
        maxWidth: screenMaxWidth,
        margin: 'auto',
        marginTop: 0,
        marginBottom: 0,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        minHeight: '100vh',
        backgroundColor: 'white',
      }}
    >
      <Helmet>
        <meta charSet="utf-8" />
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1"
        ></meta>
        <meta name="title" content="陆牛商城 Luniu Mall"></meta>
        <meta
          name="description"
          content="Luniu Mall is a fresh food distribution platform. Relying on the online e-commerce platform, it provides users with global fresh fruits and vegetables and high-quality foods. Focus on purchasing from the place of origin, direct delivery, and professional cold chain storage and shipping."
        ></meta>

        <meta property="og:type" content="website"></meta>
        <meta property="og:url" content="https://luniumall.com/"></meta>
        <meta property="og:title" content="陆牛商城 Luniu Mall"></meta>
        <meta
          property="og:description"
          content="Luniu Mall is a fresh food distribution platform. Relying on the online e-commerce platform, it provides users with global fresh fruits and vegetables and high-quality foods. Focus on purchasing from the place of origin, direct delivery, and professional cold chain storage and shipping."
        ></meta>

        <meta property="twitter:card" content="summary_large_image" />
        <meta property="twitter:url" content="https://luniumall.com/" />
        <meta property="twitter:title" content="陆牛商城 Luniu Mall" />
        <meta
          property="twitter:description"
          content="Luniu Mall is a fresh food distribution platform. Relying on the online e-commerce platform, it provides users with global fresh fruits and vegetables and high-quality foods. Focus on purchasing from the place of origin, direct delivery, and professional cold chain storage and shipping."
        ></meta>

        <title>{pageTitle + '| 陆牛商城 Luniu Mall'}</title>
        <script>{script}</script>
      </Helmet>

      <PageHeader location={location} />
      {backgroundColor ? (
        <main
          style={{ ...styles.mainContent, backgroundColor: backgroundColor }}
        >
          {children}
        </main>
      ) : (
        <main style={styles.mainContent}>{children}</main>
      )}

      <PageFooter location={location} />
      <BackTop />
    </Layout>
  );
};

const styles = createStyle({
  mainContent: {
    paddingTop: 64,
    backgroundColor: 'white',
  },
});

export default PageContainer;
